<template>
  <div class="row mt-0 mb-3 px-3">
    <div class="col-md-6">
      <a
        class="btn btn-block btn-dark"
        data-toggle="collapse"
        href="#tanques"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        Validación Tanques
      </a>
      <div class="collapse" id="tanques">
        <div class="card card-body">
          <div class="row">
            <div class="form-group col-md-10">
              <input
                type="text"
                v-model="form.tanque"
                placeholder="Descripción"
                class="form-control form-control-sm"
              />
            </div>
            <div class="col-md-2">
              <button
                class="btn bg-info col-md-12"
                v-show="!$v.form.$invalid"
                @click="cargarValidacionTanque()"
              >
                <i class="fas fa-save"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table
                class="table table-bordered table-striped table-hover table-sm"
              >
                <thead>
                  <tr>
                    <th>validación tanque</th>
                    <th style="width: 20px">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in tanques" :key="index">
                    <td>
                      {{ data.tanque }}
                    </td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        @click="destroyValidacionTanque(data.id, data.sitio_id)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "SitioValidacionTanques",
  components: {},
  data() {
    return {
      form: {
        tanque: null,
      },
      tanques: [],
    };
  },

  validations: {
    form: {
      tanque: {
        required,
      },
    },
  },

  methods: {
    cargarValidacionTanque() {
      this.form.sitio_id = this.$parent.form.id;
      this.form.accion = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: "PUT",
          url: "/api/admin/sitios/saveValidacionTanques",
          data: this.form,
        })
          .then(() => {
            this.form = {};
            this.getSitiosTanques();
            this.$swal({
              icon: "success",
              title: "Se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, el proceso ya se encuentra asignado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getSitiosTanques() {
      axios
        .get("/api/admin/sitios/show/" + this.$parent.form.id)
        .then((response) => {
          if (response.data[0].tanques != undefined) {
            this.tanques = response.data[0].tanques;
          }
        });
    },

    destroyValidacionTanque(idTanque, idSitio) {
      this.$swal({
        title: "Está seguro de eliminar esta validación de tanque?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios({
            method: "PUT",
            url: "/api/admin/sitios/destroyValidacionTanque",
            data: {
              sitio_id: idSitio,
              tanque: idTanque,
              accion: false,
            },
          }).then(() => {
            this.getSitiosTanques();
            this.$swal({
              icon: "success",
              title: "Se eliminó la validación de tanque exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },

  mounted() {
    this.getSitiosTanques();
  },
};
</script>
